import styled from "styled-components"

export const IntegrationStyled = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    .sub-title {
      text-align: center;
    }
  }
`

export const ListSSOIntegrationsStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 0;
  .item {
    padding: 0 21px;
    margin-bottom: 39px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 22px;
    img {
      max-height: 22px;
      height: auto;
    }
  }

  @media (max-width: 767px) {
    width: 80%;
  }

  @media (min-width: 768px) {
    margin: 0;
    flex-direction: column;
    width: 350px;
    .item {
      padding: 0 10px;
      height: 40px;
      img {
        max-height: 40px;
      }
    }
  }
`

export const ListHRISIntegrationsStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 0;
  .item {
    padding: 0 21px;
    margin-bottom: 39px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 22px;
    img {
      max-height: 22px;
      height: auto;
    }
  }

  @media (min-width: 768px) {
    margin-top: 80px;
    .item {
      padding: 0 10px;
      height: 30px;
      img {
        max-height: 30px;
      }
    }
  }
`
